.modal_container {
    width: 30%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
    text-align: center;
    max-height: 90vh;
    overflow-y: auto;
}

.modal_button_box {
    display: flex;
    justify-content: flex-end;
}

.modal_cancel {
    margin-right: 1rem !important;
}

.modal_title {
    display: flex;
    justify-content: center;
    margin: 0.5rem 0;
}

.modal_subtitle {
    font-weight: 500 !important;
    font-size: 20px !important;
    text-align: left;
    margin: 0 !important;
}

.modal_subtitle_container {
    border-radius: 10px !important;
}

.modal_subtitle_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.modal_list_brand {
    margin: 0 2rem !important;
}

.green_background {
    background-color: rgba(0, 128, 0, 0.25) !important;
}

.modal_textfield {
    width: 100%;
    margin: 0.5rem 0 !important;
    text-align: left;
}

.modal_flag {
    margin-right: 1rem;
}

.modal_stripe_box {
    height: 2rem;
    display: flex;
    justify-content: flex-end;
    margin-top: -1rem;
}

.modal_stripe {
    max-height: 100%;
    max-width: 100%;
    display: block;
}

.modal_cardElement {
    height: auto;
    width: 100%;
    margin-top: 0.5rem;
    border: 1px solid #c4c4c4;
    border-radius: 3px;
    padding: 0.75rem;
}

.confirmation_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1rem;
}
.confirmation_box {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 1rem;
    border-radius: 10px;
}

.confirmation_total {
    display: flex;
    justify-content: space-between;
}
