.snackbar-close-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.snackbar-text {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    color: black;
}

.snackbar-body-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.snackbar-video-box {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 1rem 0;
}

.snackbar-iframe {
    border-radius: 10px;
}

.snackbar-footer {
    display: flex;
    justify-content: center;
}

.snackbar-button {
    margin: 0.5rem !important;
    padding: 1rem !important;
    border-radius: 10px !important;
}
