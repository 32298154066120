.credit_plans {
    display: flex;
    justify-content: center;
}

/* .credit_card {
    margin: 1rem;
    padding: 1rem;
    border-radius: 10px !important;
    text-align: center;
    border: 1px solid red;
} */
