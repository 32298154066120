.ScanOptions {
    margin: 1rem 0;
}
.ScanOptions,
.scanOptionsStats,
.scanOptionsSwitches {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.scanOptionsSwitches {
    flex-direction: row !important;
    flex: 1;
}
.scanOptionsStats {
    flex: 1;
    justify-content: flex-end;
    gap: 1rem;
}
.scanChips {
    font-size: 1rem !important;
    font-weight: 700;
    border-width: 2px;
}
