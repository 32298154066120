html {
    scroll-behavior: smooth;
}
#help-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-height: 100vh; */
    /* min-width: 40rem; */
    /* border: 2px dashed green; */
    width: 100%;
}

/* #search-container {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    margin: 0 2rem 2rem 2rem;
    padding: 2rem;
    max-width: 5rem;

    box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075), 0 2px 2px hsl(0deg 0% 0% / 0.075),
        0 4px 4px hsl(0deg 0% 0% / 0.075), 0 8px 8px hsl(0deg 0% 0% / 0.075),
        0 16px 16px hsl(0deg 0% 0% / 0.075);
} */

#anchor {
    scroll-margin-top: 200px;
}
