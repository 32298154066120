.section {
    display: flex;
    flex-direction: column;
    font-family: 'Arial';
    align-items: left;
}

.section-title {
    display: flex;
    justify-content: left;
    margin: 0 2rem 0 2rem;
    padding: 0;
    /* background-color: white; */
    color: #1f2641;
    border-radius: 20px;
    font-size: 1.5rem;

    /* box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075), 0 2px 2px hsl(0deg 0% 0% / 0.075),
        0 4px 4px hsl(0deg 0% 0% / 0.075), 0 8px 8px hsl(0deg 0% 0% / 0.075),
        0 16px 16px hsl(0deg 0% 0% / 0.075); */
}
