.MuiPaper-root {
    background-color: #f0f0f0;
}

.plan-card {
    width: 24rem;
    border-radius: 10px !important;
    padding: 1rem;
    /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1) !important; */
    box-shadow: none !important;
    transition: all 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.plan-name {
    font-size: 2rem !important;
    font-weight: 700 !important;
    color: #1f2641;
    margin-bottom: 1.5rem !important;
}

.plan-desc {
    font-size: 1.25rem !important;
    font-weight: 400;
    color: #1f2641;
    margin-bottom: 1rem !important;
}
.plan-price {
    font-size: 3rem !important;
    font-weight: 700 !important;
    color: #1f2641;
}

.currency {
    text-transform: uppercase;
    font-size: 1rem;
}

.btn-centered {
    margin: 0 auto;
}
