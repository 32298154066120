.ChartLegend__item {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    gap: 0.5rem;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 700;
}

.ChartLegend__item__value {
    font-weight: 700;
}
