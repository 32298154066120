.databutton {
    margin: 0 0.5rem !important;
    padding: 0.5rem !important;
    border-radius: 10px !important;
}

.databutton.confirm {
    background-color: #4fb477;
}

.databutton.delete {
    background-color: #e55934;
}

.databutton.fb {
    color: #757575 !important;
    background-color: transparent;
    box-shadow: none;
}

.status_box {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.status_icon {
    color: #757575;
    font-size: small !important;
    margin-right: 0.5rem;
}

.status_icon.active {
    color: #4fb477;
}

.current_credits_box {
    display: flex;
    align-items: center;
}

.current_credits {
    min-width: 2.5rem;
}

.current_credits_button {
    display: flex;
    justify-content: center !important;
    margin-left: 1rem;
}

.current_credits_icon {
    color: #757575;
    transition: color 0.3s ease !important;
}

.disabled_button {
    opacity: 0.5;
    background-color: transparent !important;
}

.current_credits_icon:hover {
    color: #30bced !important;
    transform: scale(1.25);
}

.current_credits_button:hover {
    background-color: transparent !important;
}

.databutton.fb:hover {
    background-color: transparent !important;
    box-shadow: none !important;
    transform: scale(1.25);
}

.databutton.fb.delete:hover {
    color: red !important;
}

.change_credits_box {
    margin-bottom: 1rem;
}

.MU_box {
    margin: 1rem 0 0.5rem 0;
}

.MU_icon {
    color: #757575;
    transition: transform 0.3s ease !important;
    cursor: pointer;
}

.MU_button:hover {
    background-color: transparent !important;
    box-shadow: none !important;
    transform: scale(1.25);
}

.MU_button.arrow {
    padding: 0 !important;
    margin: 0 !important;
    width: fit-content;
}
