.admin_container {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    position: relative;
}

.admin_label {
    position: absolute;
    top: -16px;
    left: 8px;
    background: white;
    padding: 0 5px;
    color: #808080;
    font-size: small;
}
.admin_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 12px; */
    /* border: 1px solid #ccc; */
    /* border-radius: 4px; */
    margin: 5px 0;
}

/* .admin_box:first-of-type {
    position: relative;
}

.admin_box:first-of-type::before {
    position: absolute;
    top: -12px;
    left: 12px;
    color: #808080;
    background: white;
    padding: 0 5px;
} */

.add_button {
    color: green !important;
    font-size: x-small;
}

.delete_button {
    color: red !important;
    font-size: x-small;
}

.slide-out-left {
    animation: slideOutLeft 0.5s ease forwards;
}

@keyframes slideOutLeft {
    0% {
        transform: translateX(0%);
        opacity: 1;
    }
    100% {
        transform: translateX(-100%);
        opacity: 0;
    }
}

.slide-in-right {
    animation: slideInRight 0.5s ease forwards;
}

@keyframes slideInRight {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}
