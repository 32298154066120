.passed_overlay {
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.loading_logo {
    height: 100px;
    margin: 0 auto;
    display: block;
}
.passed_loader {
    width: 100px;
    height: 48px;
    background: radial-gradient(circle closest-side, #313b77 90%, #0000) 0% 50%,
        radial-gradient(circle closest-side, #fcba04 90%, #0000) 50% 50%,
        radial-gradient(circle closest-side, #30bced 90%, #0000) 100% 50%;
    background-size: calc(100% / 3) 24px;
    background-repeat: no-repeat;
    animation: d3 2s infinite linear;
}
@keyframes d3 {
    20% {
        background-position: 0% 0%, 50% 50%, 100% 50%;
    }
    40% {
        background-position: 0% 100%, 50% 0%, 100% 50%;
    }
    60% {
        background-position: 0% 50%, 50% 100%, 100% 0%;
    }
    80% {
        background-position: 0% 50%, 50% 50%, 100% 100%;
    }
}
