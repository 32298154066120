:root {
    --strength: 0;
}

.Results {
    width: calc(100% - 1rem);
    min-height: 950px;
    padding: 1rem;
    border-radius: 10px;
    font-size: 1rem;
    border: 1px solid #1f2641;
    font-family: monospace;
}
.Results-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 100%;
}
/* .red {
    background: rgba(255, 0, 0, var(--strength));
    color: #fff;
}
.green {
    background: rgba(0, 255, 0, calc(1 - var(--strength)));
}
.pink {
    background: rgba(255, 0, 0, var(--strength));
} */
.block {
    display: inline;
}
.line {
    margin: 0;
    padding: 0;
    white-space: pre-wrap;
}
.spacer {
    display: inline-block;
    width: 1ch;
}
