.main_page_desktop {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0rem 2.5%;
    /* border: 2px solid blue; */
}

.main_page_mobile {
    /* border: 2px solid red; */
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
}

.main_page_mobile h1 {
    font-size: 1.25rem;
}

.main_page_desktop_login {
    background: rgb(255, 255, 255);
    background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(31, 38, 65, 0.5186449579831933) 100%
    );
    padding: 0;
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
    justify-content: 'center';
    height: 100vh;
    margin: 0;
}

.main_page_mobile_login {
    padding: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(31, 38, 65, 0.5186449579831933) 100%
    );
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
    justify-content: 'center';
    height: 100vh;
    margin: 0;
}
