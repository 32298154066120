.card-container {
    display: flex;
    flex-direction: column;
    /* border-radius: 20px;
    border: solid 5px #1f2641; */
    margin: 0 2rem 0 2rem;
    padding: 0;

    /* box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075), 0 2px 2px hsl(0deg 0% 0% / 0.075),
        0 4px 4px hsl(0deg 0% 0% / 0.075), 0 8px 8px hsl(0deg 0% 0% / 0.075),
        0 16px 16px hsl(0deg 0% 0% / 0.075); */
}
.card-top {
    justify-content: space-between;
    text-align: left;
    font-size: 1.5rem;
    color: #1f2641;
    margin-top: 0;
    margin: 0 0.5rem 0 0.5rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.card-top h2,
h3 {
    margin: 0 0.5rem 0 0.5rem;
    scroll-margin-top: 50px;
    text-align: left;
}
.card-bottom {
    font-size: 1.25rem;
    padding: 0 1.5rem 1.5rem 1.5rem;
    line-height: 1.5;
}

.card-bottom ul {
    list-style-type: none;
}

.section-title h1 {
    scroll-margin-top: 100px;
}

.section-body div {
    scroll-margin-top: 100px;
}

ul {
    padding: 0;
}
