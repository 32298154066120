.learn-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 3px solid blue; */
}

.video-card {
    display: flex;
    padding: 2rem 0;
    border-radius: 10px;
    box-shadow: none;
    background-color: #f0f0f0;
    margin: 1rem 0;
}

.video-info {
    margin-left: 2rem;
}

.learn-container {
    margin-bottom: 4rem;
}
