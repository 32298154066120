.manageaccount_container {
    width: 75vw;
    --container-width: 75vw;
    margin-top: 1rem !important;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.manageaccount_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    margin: 0 1rem;
}

.manageaccount_box.billing {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.billing_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-left: 2rem !important;
    width: 100%;
}
.manageaccount_box_content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center;
    height: 100%;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 10px;
    position: relative;
    padding: 20px;
    margin: 1rem 0;
}

.manageaccount_box_content .header {
    position: absolute;
    top: -20px;
    left: 10px;
    background-color: #fff;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.54);
}

.manageaccount_box_text {
    flex: 6;
    display: flex;
    align-items: center;
    margin: 0.5rem;
}
