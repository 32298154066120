.modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40rem;
    background-color: white;
    box-shadow: 24;
    border-radius: 10px;
}

#modal-title {
    padding: 1.5rem;
}

.modal-text {
    padding: 0rem 1.5rem;
}

.modal-textfield {
    padding: 0rem 1.5rem !important;
    margin-top: 3rem !important;
    width: 100%;
}

.modal-alert {
    padding: 0rem 1.5rem !important;
    margin-top: 1rem !important;
    width: 100%;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
}
