.mui-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px !important;
}

.robot-app {
    max-width: 15rem;
    height: auto;
}
