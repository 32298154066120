.doughnut-chart__inner {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}
.doughnut-chart__inner h1 {
    margin-block-start: auto;
    margin-block-end: auto;
}
