.invoices_box {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
}

.invoices_button {
    margin: 0.5rem !important;
    border-radius: 10px !important;
    color: black !important;
    padding: 1rem !important;
    background-color: #fcba04 !important;

    transition: transform 0.3s ease;
}

.invoices_button:hover {
    /* Scale the button up slightly when hovered over */
    transform: scale(1.05);
}
