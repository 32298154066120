.Onboarding {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #f5f5f5;
    /* border: 2px dashed green; */
}

.onboarding_step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 50rem;
    background-color: #f5f5f5;
    /* border: 1px solid red; */
    /* width: 60%; */
}
.onboarding_step.step_one.active {
    min-height: 100vh;
    width: 100vw;
}

.welcome.animate__animated.animate__fadeIn {
    margin-bottom: 4rem;
}

.onboarding_progress {
    width: 100%;
    margin-bottom: 1rem;
    height: 1rem !important;
    border-radius: 20px;
    background-color: #d3d1d1 !important;
}
.css-mzxr26-MuiLinearProgress-bar1 {
    background-color: #30bced !important;
}

.onboarding_step_six {
    width: 100%;
    /* text-align: center; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.onboarding_step3_container {
    display: flex;
    justify-content: center;
}

.confirmEmail_box {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    width: 22.5rem;
}

.confirmEmail_button {
    border-radius: 10px;
    background-color: #4fb477 !important;
    color: white !important;
    padding: 0.5rem 1rem !important;
    margin-left: 1rem !important;
}

.confirmEmail_button.Mui-disabled {
    background-color: #d3d3d3 !important;
    color: #000 !important;
}

.onboarding_step4_content {
    display: flex;
    flex-direction: row;
}

.level_box {
    display: flex;
    flex-direction: column;
    width: 15rem;
    padding: 2rem 3rem !important;
    margin: 1rem 2rem !important;
    border: solid 3px #30bced !important;
    border-radius: 10px !important;
}

.level_box:hover {
    transform: scale(1.05) !important;
    background: transparent !important;
}

.level_box:focus {
    background-color: rgb(48, 188, 237, 0.3) !important;
}

.onboarding_img {
    width: 10rem;
}

.onboarding_step6_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* border: 1px solid red; */
    margin: -2rem 0;
}

.robot {
    width: 25rem;
    height: auto;
}

.robot.step6 {
    width: 20rem;
    height: auto;
}

.robot_div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    /* border: 1px solid blue; */
}

.robot_div.step6 {
    padding-right: 2rem;
}

.step_empty_div {
    min-width: 20%;
    /* border: 1px solid green; */
}

.step_empty_div.step6 {
    padding-left: 2rem;
}

.step_6_container {
    display: flex;
}
.login_button {
    font-weight: 600 !important;
    letter-spacing: 0.03em !important;
    padding: 1rem 2rem !important;
    border-radius: 10px !important;
    margin-top: 1rem !important;
    width: 100%;
}
.onboarding_step h1 {
    margin-top: 0.5rem;
}

.onboarding_logo {
    width: 40rem;
    max-width: 98%;
    margin: 0 auto;
}
.onboarding_text {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1f2641;
    margin: 1rem auto;
    max-width: 85vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 9;
    word-wrap: break-word;
}

.step_number {
    font-size: 1rem;
    font-weight: 400;
    color: #1f2641;
    max-width: 50rem;
    margin: 0;
}

.onboarding_text.light {
    font-weight: 400;
}

.onboarding_text.extralight {
    font-weight: 400;
    font-size: 1rem;
    margin: 0.25rem;
    text-align: left;
}

.onboarding_text_grid {
    text-align: center;
    text-transform: capitalize;
    padding-top: 0 !important;
    padding-bottom: 2rem !important;
}
.onboarding_grid {
    max-width: 50rem;
    margin-top: 1rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.onboarding_payment_container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
}

.onboarding_payment_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    /* border: 2px solid red; */
}

.onboarding_payment_box:nth-child(1) {
    justify-content: center;
    align-items: center;
}

.gif {
    max-width: 22.5rem;
}

.onboarding_note_container {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    font-size: 1rem;
}

.onboarding_note {
    display: flex;
    align-items: center;
    margin: 0;
}

.stripe_logo {
    /* margin-top: 1rem; */
    max-width: 5rem;
}

.xl-icon {
    transform: scale(2);
    margin: 2rem 0;
}

.payment_button {
    margin: 0 !important;
    padding: 1rem !important;
    width: 25rem;
    display: flex !important;
    justify-content: space-between !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    border-radius: 10px !important;
}

.feature_container {
    margin-top: 1rem;
}

.feature_list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.feature_list:nth-child(1) {
    margin-right: 1rem;
}

.welcome {
    margin: 0 auto 0.5rem auto;
}
.name_confirm {
    margin: 0 auto;
    font-size: 1.5rem;
    font-weight: 600;
}

.onboarding_icons {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center;
    align-self: center;
}
.onboarding_button {
    background-color: #fcba04 !important;
    color: #1f2641 !important;
    font-weight: 600 !important;
    letter-spacing: 0.03em !important;
    padding: 1rem 2rem !important;
    border-radius: 10px !important;
    margin-top: 1rem !important;
    width: 100%;
}

.onboarding_button.Mui-disabled {
    background-color: #d3d3d3 !important;
    color: #000 !important;
}
.onboarding_button.prev {
    background-color: transparent !important;
    color: #1f2641 !important;
}
.onboarding_step_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 22.5rem;
}
.onboarding_step_content_item {
    width: 22.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 2rem auto;
}
.onboarding_step_content_item.col {
    flex-direction: column;
}
.onboarding_input {
    width: 60%;
    max-width: 22.5rem;
    padding: 1rem;
    margin: 0 auto;
    border: 1px solid #1f2641;
    border-radius: 10px;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    color: #1f2641;
}

.onboarding_step_footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 50rem;
    padding: 0 1rem;
    margin-top: 1rem;
}
