.users_container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.users_table {
    margin-top: 1rem;
    width: 100%;
}

.download_csv_button {
    display: flex;
    flex-direction: column;
    text-align: center;
    flex: 1;
    background-color: #f8f9fa !important;
    border: 2px solid #e9ecef !important;
    padding: 1rem !important;
}

.download_csv_icon {
    font-size: 6rem !important;
    color: #868e96;
}

.download_text {
    text-transform: initial;
    font-size: 1rem;
    margin: 1rem 0;
    color: #868e96;
}

/* .download_csv_button_box {
    width: 100%;
    text-align: center;
} */

.drop_and_template {
    display: flex;
    width: 100%;
}

.dropzone_info_alert {
    background-color: white !important;
    display: flex !important;
    justify-content: flex-start !important;
}

.dropzone_warning_alert {
    margin: 1rem 0 !important;
}

.drop-zone.csv {
    flex: 3;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    margin: 0 2rem 0 0 !important;
}

.drop-zone {
    background-color: #f8f9fa;
    border: 2px dashed #e9ecef;
    border-radius: 0.25rem;
    color: #868e96;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 1rem;
    text-align: center;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 1rem;
    cursor: pointer;
}
.drop-zone.active {
    border-color: #4fb477;
}
